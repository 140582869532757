<template>
  <div>
    <!-- experience and satisfaction -->
    <div class="metrics">
      <template v-if="kpis && !loading">
        <!-- experience -->
        <div :class="classConfig" v-html="kpis.experience" />
        <!-- satisfaction -->
        <div :class="classConfig" v-html="kpis.satisfaction" />
      </template>

      <template v-else>
        <Skeleton width="106px" height="18px" border-radius="12px" />
        <Skeleton width="100px" height="18px" border-radius="12px" />
      </template>
    </div>

    <slot name="specialist-card" />

    <!-- services and availability -->
    <template v-if="origin !== 'profile'">
      <div class="services">
        <template v-if="kpis && kpis.pricing_starts">
          <div
            class="content-small text-gray-60"
            v-html="kpis.pricing_starts"
          />
          <div
            @click.stop="openSpecialistServices"
            class="see-more content-title text-purple"
            v-text="$translations.general['see-more']"
          />
        </template>
        <template v-else>
          <Skeleton width="145px" height="20px" border-radius="12px" />
        </template>
      </div>

      <div class="availability">
        <template v-if="kpis && !loading">
          <Chip
            color="purple"
            :show-icon="true"
            icon-position="left"
            :icon="'icon-ray-off'"
            size="small"
            :text="kpis.availability.date"
          />

          <div class="availability-items">
            <div
              class="availability-items-text"
              v-for="(slot, index) in kpis.availability.slots"
              :key="index"
            >
              <div
                class="body-small text-gray-80"
                v-if="
                  !(
                    (index >= 1 && windowWidth < 340) ||
                    (index >= 2 && windowWidth < 410)
                  )
                "
              >
                {{ availabilityItem(index, slot) }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <Skeleton width="152px" height="24px" border-radius="12px" />
          <Skeleton width="195px" height="24px" border-radius="12px" />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { SpecialistKpis, Chip, Skeleton } from '@seliaco/red-panda'
import { BookingAndAppointmentDetailEvent } from '@/types/events'
import ResponsiveMixin from '@/mixins/responsive.mixin'

export default {
  name: 'Metrics',
  components: { Chip, Skeleton },
  mixins: [ResponsiveMixin],
  props: {
    specialistId: String,
    origin: {
      type: String,
      default: 'profile'
    }
  },
  mounted () {
    if (this.specialistId) {
      this.getData()
    }
  },
  data () {
    return {
      kpis: null,
      loading: false,
      specialistCard: 'specialist-card'
    }
  },
  methods: {
    getData () {
      this.loading = true
      const includeData = this.origin !== 'profile'

      SpecialistKpis.getSpecialistKpisV2(
        this.specialistId,
        includeData,
        includeData
      )
        .then((data) => {
          this.kpis = data
        })
        .finally(() => {
          this.loading = false
        })
    },
    openSpecialistServices () {
      this.$router
        .push({
          name: 'SpecialistDetail',
          params: {
            id: this.specialistId
          },
          query: {
            origin: this.specialistCard,
            back: this.$route.fullPath
          },
          hash: 'services'
        })
        .then(() =>
          BookingAndAppointmentDetailEvent.Select_View_Specialist(this.body)
        )
    },
    availabilityItem (index, slot) {
      let msg = `${slot}`

      if (index > 0) {
        msg = `• ${slot}`
      }

      return msg
    }
  },
  computed: {
    classConfig () {
      return {
        'body-small': this.origin === 'specialist-card',
        body: this.origin === 'profile'
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.metrics
  display: flex
  gap: 16px
  padding: 16px
  align-items: center
.content-text
  font-size: var(--base)
  font-weight: 600
  line-height: 24px
.skeleton
  display: flex
  gap: 16px

.services
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 8px
  margin-top: 16px
  .see-more
    cursor: pointer

.availability
  display: flex
  gap: 8px
  @media (min-width: 1040px)
    gap: 16px
    padding: 8px 0
  &-items
    display: flex
    align-items: center
    gap: 4px
    &-text
      overflow: hidden
    .body-small
      white-space: nowrap
      .hidden
        display: none

.metrics-skeleton
  display: flex
  flex-direction: column
  gap: 16px
  padding: 16px 0

  &-profile
    padding: 16px
  &-item,
  &-availability
    width: 100%
    border-radius: 32px

  &-item
    height: 23px
  &-availability
    height: 18px

@media (max-width: 840px)
  .metrics-skeleton
    &-item
      height: 22px
    &-availability
      height: 16px
</style>
