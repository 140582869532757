<template>
  <!-- DEPRECATED -->
  <div
    v-selectable-container="{ callback: openSpecialistProfile }"
    class="margin-bottom-sm segment"
  >
    <SpecialistCardHeader :specialist="specialist" />

    <Metrics :specialist-id="specialist.id" :origin="'specialist-card'">
      <template v-slot:specialist-card>
        <!-- specialities | bio | consultationStyle | firstSession -->
        <template v-for="content in specialistContents">
          <div
            v-if="specialist[content.key] && specialistContentVersion === content.version"
            :key="content.key"
            class="section-container-gray info-content"
          >
            <span class="body-small info-content-text" v-html="specialist[content.key]" />
          </div>
        </template>
      </template>
    </Metrics>

    <div class="banner-button">
      <Button
        :text="$translations.buttons['view-profile']"
        @clicked="openSpecialistProfile"
        :type="'outline'"
        class="button"
      />
      <Button
        v-if="specialist.is_public"
        :text="$translations['specialist-page']['segmented-control'][1]"
        @clicked="schedule"
        :icon-left="true"
        :icon="'icon-appointment-off'"
        class="button"
      />
    </div>
  </div>
</template>

<script>
import { Button, selectableContainer } from '@seliaco/red-panda'
import ResponsiveMixin from '@/mixins/responsive.mixin'
import {
  BookingAndAppointmentDetailEvent,
  ScheduleButton
} from '@/types/events'
import Metrics from '@/components/MetricsV2.vue'
import SpecialistCardHeader from '@/components/cards/specialist-card/SpecialistCardHeader.vue'

export default {
  name: 'SpecialistCardV2',
  directives: { selectableContainer },
  mixins: [ResponsiveMixin],
  components: {
    SpecialistCardHeader,
    Metrics,
    Button
  },
  props: {
    specialist: Object
  },
  data () {
    return {
      specialistCard: 'specialist-card',
      kpis: {},
      specialistContents: [
        { key: 'specialitiesDescription', version: 0 },
        { key: 'bioDescription', version: 1 },
        { key: 'consultationStyle', version: 2 },
        { key: 'firstSession', version: 3 }
      ],
    specialistContentVersion: 0
    }
  },
  methods: {
    openSpecialistProfile () {
      this.$router
        .push({
          name: 'SpecialistDetail',
          params: {
            id: this.specialist.id
          },
          query: {
            origin: this.specialistCard,
            back: this.$route.fullPath
          }
        })
        .then(() => {
          BookingAndAppointmentDetailEvent.Select_View_Specialist(
            this.segmentData()
          )
        })
    },
    schedule () {
      ScheduleButton.onClick(this.segmentData())
      this.$router.push({
        name: 'Schedule',
        query: {
          specialistId: this.specialist.id,
          back: this.$route.fullPath,
          origin: this.specialistCard
        }
      })
    },
    segmentData () {
      return {
        user: this.$store.getters['auth/user'],
        origin: this.$route.name,
        specialist_id: this.specialist.id,
        specialist_name: `${this.specialist.user.first_name} ${this.specialist.user.last_name}`,
        specialist: this.specialist,
        component: 'Specialist Card',
        is_public: this.specialist.is_public
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.banner-button
  display: flex
  gap: 8px
  width: 100%
  margin-top: 8px
  .button
    width: 100%
.icon-section
  display: grid
  grid-template-columns: min-content 1fr
  margin-top: 4px
  .semi-circle
    display: flex
    align-items: center
    align-self: initial
    height: 16px
    color: var(--purple)
    background-color: var(--purple-20)
    border-radius: 4px
    padding: 0 2px
    margin-right: 4px
    max-width: 16px
    .icon
      font-size: 12px
.info-content
  display: grid
  margin-bottom: 16px
  &-text
    text-overflow: ellipsis
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: 4
    -webkit-box-orient: vertical
  .icon-container
    color: var(--purple)
    height: auto
    border-radius: 4px
    padding: 8px 0
    background: var(--purple-20)
</style>
