<template>
  <div v-if="!isLoading">
    <SpecialistCardV3
      :specialist="specialist"
      :content-version="contentVersion"
      v-if="specialist.name"
    />
    <SpecialistCardV2 :specialist="specialist" v-else />

    <QuestionnaireBanner
      class="margin-bottom-sm"
      origin="Specialists"
      :key="specialist.id"
      :title="$translations.search.banner.title"
      :button-text="$translations.search.banner.button"
      v-if="showBanner"
    />
  </div>
</template>

<script>
import SpecialistCardV2 from '@/components/cards/specialist-card/SpecialistCardV2.vue'
import SpecialistCardV3 from '@/components/cards/specialist-card/SpecialistCardV3.vue'
import QuestionnaireBanner from '@/components/banner/QuestionnaireBanner.vue'

export default {
  name: 'SpecialistCardWrapper',
  components: {
    SpecialistCardV2,
    SpecialistCardV3,
    QuestionnaireBanner
  },
  props: {
    specialist: Object,
    showBanner: Boolean,
    isLoading: Boolean,
    contentVersion: Number
  }
}
</script>
