export default {
  data () {
    return {
      windowWidth: 0
    }
  },
  mounted () {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  computed: {
    isMobile: {
      get () {
        return this.windowWidth < 1040
      },
      set (windowWidth) {
        return windowWidth < 1040
      }
    }
  }
}
