<template>
  <div
    class="banner"
    :class="{ 'banner-white': type === 'white' }"
    :style="bannerStyle"
    v-selectable-container="{ callback: goQuestionnaire }"
  >
    <div class="heading-mate banner-text" v-text="getTitle" />
    <Button
      class="banner-button"
      :color="type === 'purple' ? 'white' : 'purple'"
      :text="getButtonText"
      @clicked="goQuestionnaire"
    />
  </div>
</template>

<script>
import { Button, selectableContainer, RoleMixin } from '@seliaco/red-panda'
import { HomeEvent, SpecialistSearch } from '@/types/events'
import { mapGetters } from 'vuex'

export default {
  name: 'QuestionnaireBanner',
  components: {
    Button
  },
  mixins: [RoleMixin],
  directives: {
    selectableContainer
  },
  props: {
    type: {
      type: String,
      default: 'purple'
    },
    title: String,
    buttonText: String,
    origin: String
  },
  methods: {
    hadFiltersSelected () {
      const hadFiltersSelected = Object.values(
        this.$store.getters['filters/get']
      ).find((option) => {
        return option?.length
      })
      return Boolean(hadFiltersSelected)
    },
    callSegmentEvent () {
      switch (this.origin) {
        case 'Home': {
          const body = { user: this.$store.getters['auth/user'] }
          HomeEvent.Home_banner_Click(body)
          break
        }

        case 'Specialists': {
          const body = {
            option: this.$store.getters['filters/getType'],
            hadFiltersSelected: this.hadFiltersSelected(),
            filters: this.$store.getters['filters/get']
          }
          SpecialistSearch.Specialists_Banner_Click(body)
          break
        }
      }
    },
    goQuestionnaire () {
      this.callSegmentEvent()
      this.$store.commit('flow/RESET_MATCHING')
      this.$router.push({
        name: 'QuestionnaireContainer'
      })
    }
  },
  computed: {
    bannerStyle () {
      return {
        '--banner-background':
          this.type === 'purple' ? 'var(--purple)' : 'var(--white)',
        '--banner-color':
          this.type === 'purple' ? 'var(--purple-5)' : 'var(--gray-80)',
        '--banner-img':
          this.type === 'purple'
            ? `url('${require('../../assets/banner-img.png')}')`
            : `url('${require('../../assets/banner-img-alt.png')}')`
      }
    },
    getTitle () {
      return this.title
        ? this.title
        : this.$translations.home['questionnaire-banner']
    },
    getButtonText () {
      return this.buttonText
        ? this.buttonText
        : this.$translations.home['banner-button']
    },
    ...mapGetters({
      role: 'auth/role'
    })
  }
}
</script>

<style lang="sass" scoped>
.banner-bg
  background: transparent!important
.banner
  display: flex
  flex-direction: column
  border-radius: 16px
  background: var(--banner-background)
  padding: 16px
  align-items: flex-start
  justify-content: center
  gap: 16px
  max-width: 480px
  height: 162px
  position: relative
  overflow: hidden
  background-image: var(--banner-img)
  background-position: right bottom
  background-repeat: no-repeat
  background-size: 138px
  border: none
  box-shadow: none
  &:hover
    box-shadow: 0px 8px 8px rgba(141, 145, 160, 0.2)!important
  &-text
    color: var(--banner-color)
    z-index: 3
    max-width: 70%
  .btn
    padding: 12px
  &-button
    width: auto
    z-index: 2

  &.banner-white
    border: 2px solid var(--gray-10) !important
    box-shadow: 0px 5px 8px rgba(141, 145, 160, 0.1)
</style>
