<template>
  <div
    v-selectable-container="{ callback: openSpecialistProfile }"
    class="margin-bottom-sm segment"
  >
    <Header :specialist="specialist" />

    <!-- experience and satisfaction -->
    <div class="metrics">
      <!-- experience -->
      <div class="body-small" v-html="specialist.kpis.experience" />
      <!-- satisfaction -->
      <div
        class="body-small metrics-satisfaction"
        v-html="specialist.kpis.satisfaction"
      />
    </div>

    <!-- specialities | bio | consultationStyle | firstSession -->
    <template v-for="content in specialistContents">
      <div
        v-if="handlerContent(content)"
        :key="content.key"
        class="section-container-gray info-content"
      >
        <span
          class="body-small info-content-text"
          v-html="specialist[content.key]"
        />
      </div>
    </template>

    <!-- pricing -->
    <div
      class="services"
      v-if="allowedByRole([roleType.USER, roleType.USER_B2B])"
    >
      <div
        class="content-small text-gray-60"
        v-html="specialist.pricing_starts"
      />
      <div
        @click.stop="openSpecialistServices"
        class="see-more content-title text-purple"
        v-text="$translations.general['see-more']"
      />
    </div>

    <!-- availability -->
    <div class="availability">
      <Chip
        v-if="specialist.availability && specialist.availability.date"
        color="purple"
        :show-icon="true"
        icon-position="left"
        :icon="'icon-ray-off'"
        size="small"
        :text="specialist.availability.date"
      />

      <div class="availability-items" v-if="specialist.availability">
        <div class="availability-items-text body-small text-gray-80">
          {{ availabilityItem() }}
        </div>
      </div>
    </div>

    <!-- action -->
    <div class="banner-button">
      <Button
        :text="$translations.buttons['view-profile']"
        @clicked="openSpecialistProfile"
        :type="'outline'"
        class="button"
      />

      <Button
        v-if="specialist.is_public"
        :text="$translations['specialist-page']['segmented-control'][1]"
        @clicked="schedule"
        :icon-left="true"
        :icon="'icon-appointment-off'"
        class="button"
      />
    </div>
  </div>
</template>

<script>
import {
  Button,
  selectableContainer,
  Chip,
  RoleMixin
} from '@seliaco/red-panda'
import ResponsiveMixin from '@/mixins/responsive.mixin'
import {
  BookingAndAppointmentDetailEvent,
  ScheduleButton
} from '@/types/events'
import SpecialistCardHeaderV3 from '@/components/cards/specialist-card/SpecialistCardHeaderV3.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SpecialistCardV3',
  directives: { selectableContainer },
  mixins: [ResponsiveMixin, RoleMixin],
  components: {
    Header: SpecialistCardHeaderV3,
    Button,
    Chip
  },
  props: {
    specialist: Object,
    contentVersion: Number
  },
  data () {
    return {
      specialistCard: 'specialist-card',
      kpis: {},
      specialistContents: [
        { key: 'specialities', version: 0 },
        { key: 'bio', version: 1 },
        { key: 'consultationStyle', version: 2 },
        { key: 'firstSession', version: 3 }
      ],
      specialistContentVersion: 0
    }
  },
  methods: {
    savePosition () {
      const scrollPosition =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      localStorage.setItem('scrollPosition', scrollPosition.toString())
    },
    openSpecialistProfile () {
      this.savePosition()

      this.$router
        .push({
          name: 'SpecialistDetail',
          params: {
            id: this.specialist.id
          },
          query: {
            origin: this.specialistCard,
            back: this.$route.fullPath
          }
        })
        .then(() => {
          BookingAndAppointmentDetailEvent.Select_View_Specialist(
            this.segmentData()
          )
        })
    },
    openSpecialistServices () {
      this.savePosition()
      this.$router
        .push({
          name: 'SpecialistDetail',
          params: {
            id: this.specialist.id
          },
          query: {
            origin: this.specialistCard,
            back: this.$route.fullPath
          },
          hash: 'services'
        })
        .then(() =>
          BookingAndAppointmentDetailEvent.Select_View_Specialist(
            this.segmentData()
          )
        )
    },
    schedule () {
      this.savePosition()
      ScheduleButton.onClick(this.segmentData())
      this.$router.push({
        name: 'Schedule',
        query: {
          specialistId: this.specialist.id,
          back: this.$route.fullPath,
          origin: this.specialistCard
        }
      })
    },
    segmentData () {
      return {
        user: this.$store.getters['auth/user'],
        origin: 'Directory',
        specialist_id: this.specialist.id,
        specialist_name:
          this.specialist.name ||
          `${this.specialist.user.first_name} ${this.specialist.user.last_name}`,
        specialist: this.specialist,
        is_public: this.specialist.is_public
      }
    },
    availabilityItem () {
      let result = ''
      for (let i = 0; i < this.specialist.availability.slots.length; i++) {
        let textslot = `${this.specialist.availability.slots[i]}`
        if (i > 0) {
          textslot = `\xa0• ${this.specialist.availability.slots[i]}`
        }
        result += textslot
      }
      return result
    }
  },
  computed: {
    ...mapGetters({
      role: 'auth/role'
    }),
    handlerContent () {
      return (content) => {
        if (this.contentVersion) {
          return this.specialist[content.key]
        }

        return (
          this.specialist[content.key] &&
          this.specialistContentVersion === content.version
        )
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.banner-button
  display: flex
  gap: 8px
  width: 100%
  margin-top: 8px
  .button
    width: 100%

.icon-section
  display: grid
  grid-template-columns: min-content 1fr
  margin-top: 4px
  .semi-circle
    display: flex
    align-items: center
    align-self: initial
    height: 16px
    color: var(--purple)
    background-color: var(--purple-20)
    border-radius: 4px
    padding: 0 2px
    margin-right: 4px
    max-width: 16px
    .icon
      font-size: 12px

.metrics
  display: flex
  gap: 16px
  padding: 16px 8px
  align-items: center
  &-satisfaction
    display: flex
    gap: 4px
    align-items: center

.info-content
  display: grid
  margin-bottom: 16px
  &-text
    text-overflow: ellipsis
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: 4
    -webkit-box-orient: vertical

  .icon-container
    color: var(--purple)
    height: auto
    border-radius: 4px
    padding: 8px 0
    background: var(--purple-20)

.services
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 8px
  .see-more
    cursor: pointer

.availability
  display: flex
  gap: 8px
  padding: 8px 0

  @media (min-width: 1040px)
    gap: 16px

  &-items
    display: flex
    align-items: center
    width: 100%
    overflow: hidden
    &-text
      white-space: nowrap
      overflow: hidden
      text-overflow: ellipsis
</style>
