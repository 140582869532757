<template>
  <div class="card-header">
    <div>
      <div class="avatar-content" v-if="multimedia.type === 'VIDEO'" />
      <div class="card-header-avatar">
        <img
          class="video-icon"
          :src="videoIcon"
          alt=""
          v-if="multimedia.type === 'VIDEO'"
        />
        <img class="avatar" :src="avatar" alt="" @click.stop="openMultimedia" />
        <img v-if="countryFlag" class="country" :src="countryFlag" alt="" />
      </div>
    </div>

    <div class="avatar-modal" v-if="showModalPic">
      <div
        class="avatar-modal-backdrop cursor-pointer"
        @click.stop="closeModalPic"
      />
      <img class="avatar-modal-pic modal-in" :src="avatar" alt="" ref="modal" />
    </div>

    <YouTubePlayer
      v-if="showMultimediaPlayer"
      :title="multimedia.title"
      :url="multimedia.url"
      @close="showMultimediaPlayer = false"
    />

    <div class="middle-column">
      <!-- full name -->
      <div class="heading-tag" v-text="specialist.name" />

      <!-- speciality -->
      <div v-if="specialist.speciality" class="icon-section">
        <span class="brief-case small">
          <span class="icon icon-briefcase-off" />
        </span>
        <span class="body-small" v-text="specialist.speciality" />
      </div>

      <!-- languages -->
      <div v-if="languages" class="icon-section">
        <span class="language small">
          <span class="icon icon-language" />
        </span>

        <span class="body-small" v-text="languages" />
      </div>
    </div>

    <!-- arrow -->
    <div class="arrow-icon icon-display icon-arrow-big-outline-right" />
  </div>
</template>

<script>
import { FLAGS, SpecialistProfile, YouTubePlayer } from '@seliaco/red-panda'
import { SpecialistProfileEvent } from '@/types/events'
import { videoIcon } from '@seliaco/red-panda/src/assets/svg'

export default {
  name: 'SpecialistCardHeaderV3',
  components: { YouTubePlayer },
  props: {
    specialist: Object
  },
  data () {
    return {
      showModalPic: false,
      multimedia: {
        duration: null,
        url: null,
        thumbnail: null,
        title: null,
        type: null
      },
      showMultimediaPlayer: false,
      videoIcon: videoIcon
    }
  },
  created () {
    this.getMedia()
  },
  methods: {
    getMedia () {
      // To change after updating endpoint that returns the specialist data
      SpecialistProfile.getMultimedia(this.specialist.id).then((multimedia) => {
        if (multimedia && multimedia.type === 'VIDEO') {
          this.multimedia = multimedia
        }
      })
    },
    openMultimedia () {
      if (this.multimedia.type === 'VIDEO') {
        this.showMultimediaPlayer = true
        this.$segment.track(SpecialistProfileEvent.select_playspecialistvideo)
      } else {
        this.showModalPic = true
      }
    },
    closeModalPic () {
      this.$refs.modal.classList.remove('modal-in')
      this.$refs.modal.classList.add('modal-out')
      setTimeout(() => {
        this.showModalPic = false
      }, 140)
    }
  },
  computed: {
    avatar () {
      return this.specialist.picture || require('@/assets/images/avatar.png')
    },
    languages () {
      if (!this.specialist.languages) {
        return null
      }

      const joined = this.specialist.languages
        .map((o) => this.$translations.languages[o])
        .join(', ')

      return `${joined}`
    },
    countryFlag () {
      if (!this.specialist.country) {
        return null
      }

      return FLAGS[`${this.specialist.country.toLowerCase()}Flag`]
    }
  }
}
</script>

<style lang="sass" scoped>
.card-header
  display: grid
  gap: 16px
  grid-template-columns: 64px 1fr min-content
  align-content: center
  position: relative
  &-avatar
    position: relative
  .avatar
    flex: none
    object-position: center
    object-fit: cover
    height: 4rem
    width: 4rem
    border-radius: 9999px
    border: 2px solid var(--white)
    &-content
      background: linear-gradient(130deg, var(--purple-20) 24%, var(--purple) 60%, var(--purple-60) 20%)
      animation: gradient 2s ease infinite
      background-size: 400% 400%
      width: 68px
      height: 68px
      position: absolute
      display: block
      top: -2px
      border-radius: 50%
      left: -2px

  .country
    flex: none
    position: absolute
    left: 44px
    bottom: -1px
    object-fit: cover
    height: 24px
    width: 24px
    border-radius: 5px
  .video-icon
    flex: none
    position: absolute
    left: 24px
    bottom: -2px
    object-fit: cover
    height: 16px
    width: 24px
    border-radius: 4px
    z-index: 10
  .middle-column
    align-self: start
  .arrow-icon
    align-self: center

.icon-section
  display: grid
  grid-template-columns: min-content 1fr
  margin-top: 4px
  .brief-case
    display: flex
    align-items: center
    align-self: center
    height: 16px
    color: var(--blue)
    background-color: var(--blue-20)
    border-radius: 4px
    padding: 0 2px
    margin-right: 4px
    max-width: 16px
    .icon
      font-size: 12px
  .language
    display: flex
    align-items: center
    align-self: center
    height: 16px
    color: var(--green)
    background-color: var(--green-20)
    border-radius: 4px
    padding: 0 2px
    margin-right: 4px
    max-width: 16px
    .icon
      font-size: 12px
  .semi-circle
    display: flex
    align-items: center
    align-self: initial
    height: 16px
    color: var(--purple)
    background-color: var(--purple-20)
    border-radius: 4px
    padding: 0 2px
    margin-right: 4px
    max-width: 16px
    .icon
      font-size: 12px

.avatar
  position: relative
  transition: 2s
  &-modal
    position: fixed
    z-index: 1000
    &-backdrop
      position: fixed
      width: 100%
      height: 100%
      background: rgba(0, 0, 0, 0.4)
      top: 0
      left: 0
    &-pic
      position: fixed
      max-width: calc( 100vw - 32px )
      height: 343px
      top: 50%
      left: 50%
      transform: translate(-50%,-50%)
      opacity: 1
      object-fit: cover
      border-radius: 16px

    .modal-in
      animation: modalInCenter .15s ease-in-out

    .modal-out
      animation: modalOutCenter .15s ease-in-out

@keyframes gradient
  0%
    background-position: 0% 50%
  50%
    background-position: 100% 50%
  100%
    background-position: 0% 50%
</style>
